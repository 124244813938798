<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="rightCenter" style="width:100%;height:100%;"></div>
		</div>


	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	import 'echarts-liquidfill'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.$request('/center/home/rightTwo').then(res => {
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].name = res.data[i].type
					res.data[i].value = res.data[i].count
				}
				this.init(res.data);
			})
		},
		methods: {
			init(optionsData) {
				console.log(optionsData);
				let that = this
				let myChart = echarts.init(document.getElementById("rightCenter"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});


				// 水球内部数据--蓝色
				var value = 0.32;
				// 水球颞部数据--灰色
				var value3 = 76
				var value1 = 75.2
				var value2 = 75.2

				var data = [value, value, value];

				var option = {
					// 圆环内部文字
					// title: [{
					// 		text: '午睡时长',
					// 		left: '50%',
					// 		top: "58%",
					// 		textAlign: 'center',
					// 		textStyle: {
					// 			fontSize: '24',
					// 			fontWeight: '400',
					// 			color: '#fff',
					// 			textAlign: 'center',
					// 		},
					// 	},
					// 	{
					// 		text: 'min',
					// 		left: '50%',
					// 		top: "50%",
					// 		textAlign: 'center',
					// 		textStyle: {
					// 			fontSize: '20',
					// 			color: '#8492A6',

					// 			textAlign: 'center',
					// 		},
					// 	},
					// 	{
					// 		text: '(较班级)',
					// 		left: '50%',
					// 		top: "64%",
					// 		textAlign: 'center',
					// 		textStyle: {
					// 			fontSize: '20',
					// 			fontWeight: '400',
					// 			color: '#fff',

					// 			textAlign: 'center',
					// 		},
					// 	}
					// ],
					series: [{
							type: 'liquidFill',
							center: ['50%', '55%'],
							radius: '65%',
							z: 6,
							itemStyle: {
								normal: {
									opacity: 0.4,
									shadowBlur: 0,
									shadowColor: 'blue'
								}
							},
							amplitude: 8,
							backgroundStyle: {
								color: {
									type: 'radial',
									x: 0.5,
									y: 0.5,
									r: 0.5,
									colorStops: [{
											offset: 0,
											color: '#E0EEFC',
										},
										{
											offset: 0.75,
											color: '#D4E8FA',
										},
										{
											offset: 1,
											color: '#B1D6F9',
										},
									],

								},
							},
							color: ['#53d5ff'],
							data: [{
								value: 0.6,
								itemStyle: {
									normal: {
										color: '#4ac4e5',
										opacity: 0.7
									}
								}
							}],
							label: {
								normal: {
									formatter: '',
								}
							},
							outline: {
								show: true,
								itemStyle: {
									borderWidth: 0,
								},
								borderDistance: 0,
							}
						},
						{
							type: 'pie',							z: 1,							zlevel: -1,							radius: ['65%', '80%'],							center: ['50%', '55%'],							color: ['#FFA91C', '#FF7377', '#33D695', '#7D9CFF', '#A5D63F', '#FFEB3B', '#2A82E4',								'#7948EA'							],							hoverAnimation: false,							label: {								show: true,								padding: [0, 0, 0, 0],								// 展示所有标签								formatter: '{b}\n{d}%',
								color:'#a6e4ff',								rich: {									name: {										fontSize: 16,										color: '#a6e4ff'									}								}							},							labelLine: {								show: true,								length: 10,								length2: 10,								lineStyle: {									color: "#a6e4ff" // 设置标示线的颜色								}							},							data: optionsData
						},
					]
				}


				myChart.setOption(option);

				setInterval(function() {
					myChart.clear()
					myChart.setOption(option);
				}, 20000)
				return;

				function getParametricEquation(
					startRatio,
					endRatio,
					isSelected,
					isHovered,
					k,
					height,
					i
				) {
					// 计算
					let midRatio = (startRatio + endRatio) / 2;

					let startRadian = startRatio * Math.PI * 2;
					let endRadian = endRatio * Math.PI * 2;
					let midRadian = midRatio * Math.PI * 2;

					// 如果只有一个扇形，则不实现选中效果。
					if (startRatio === 0 && endRatio === 1) {
						isSelected = false;
					}

					// 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
					k = typeof k !== "undefined" ? k : 1 / 3;

					// 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
					let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
					let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
					let offsetZ = i == 1 ? 2 : 0;
					// 计算高亮效果的放大比例（未高亮，则比例为 1）
					let hoverRate = isHovered ? 1.05 : 1;

					// 返回曲面参数方程
					return {
						u: {
							min: -Math.PI,
							max: Math.PI * 3,
							step: Math.PI / 32,
						},

						v: {
							min: 0,
							max: Math.PI * 2,
							step: Math.PI / 20,
						},

						x: function(u, v) {
							if (u < startRadian) {
								return (
									offsetX +
									Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
								);
							}
							if (u > endRadian) {
								return (
									offsetX +
									Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
								);
							}
							return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
						},

						y: function(u, v) {
							if (u < startRadian) {
								return (
									offsetY +
									Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
								);
							}
							if (u > endRadian) {
								return (
									offsetY +
									Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
								);
							}
							return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
						},

						z: function(u, v) {
							if (u < -Math.PI * 0.5) {
								return Math.sin(u);
							}
							if (u > Math.PI * 2.5) {
								return Math.sin(u);
							}
							return Math.sin(v) > 0 ? 1 * height : -1;
						},
					};
				}
				// 生成模拟 3D 饼图的配置项
				function getPie3D(pieData, internalDiameterRatio) {
					let series = [];
					let sumValue = 0;
					let startValue = 0;
					let endValue = 0;
					let legendData = [];
					let k =
						typeof internalDiameterRatio !== "undefined" ?
						(1 - internalDiameterRatio) / (1 + internalDiameterRatio) :
						1 / 3;

					// 为每一个饼图数据，生成一个 series-surface 配置
					for (let i = 0; i < pieData.length; i++) {
						sumValue += pieData[i].value;

						let seriesItem = {
							name: typeof pieData[i].name === "undefined" ?
								`series${i}` : pieData[i].name,
							type: "surface",
							parametric: true,
							wireframe: {
								show: false,
							},
							pieData: pieData[i],
							pieStatus: {
								selected: false,
								hovered: false,
								k: k,
							},
						};

						if (typeof pieData[i].itemStyle != "undefined") {
							let itemStyle = {};

							typeof pieData[i].itemStyle.color != "undefined" ?
								(itemStyle.color = pieData[i].itemStyle.color) :
								null;
							typeof pieData[i].itemStyle.opacity != "undefined" ?
								(itemStyle.opacity = pieData[i].itemStyle.opacity) :
								null;

							seriesItem.itemStyle = itemStyle;
						}
						series.push(seriesItem);
					}

					// 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
					// 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
					for (let i = 0; i < series.length; i++) {
						endValue = startValue + series[i].pieData.value;
						series[i].pieData.startRatio = startValue / sumValue;
						series[i].pieData.endRatio = endValue / sumValue;
						series[i].parametricEquation = getParametricEquation(
							series[i].pieData.startRatio,
							series[i].pieData.endRatio,
							false,
							false,
							k,
							// 调整扇形高度
							i === 0 ? 10 : 10,
							i,
							series[i].pieData.value
						);

						startValue = endValue;

						legendData.push(series[i].name);
					}
					return series;
				}
				// 传入数据生成 option
				// const optionsData = [{
				// 		name: "猪",
				// 		value: 4256,
				// 		itemStyle: {
				// 			//   opacity: 0.5,
				// 			color: "#2A71FF",
				// 		},
				// 	},

				// 	{
				// 		name: "鸡",
				// 		value: 2356,
				// 		itemStyle: {
				// 			//   opacity: 0.5,
				// 			color: "#00EDFE",
				// 		},
				// 	},
				// 	{
				// 		name: "牛",
				// 		value: 2018,
				// 		itemStyle: {
				// 			//   opacity: 0.5,
				// 			color: "#FEDB4B",
				// 		},
				// 	},
				// 	{
				// 		name: "鸭",
				// 		value: 1998,
				// 		itemStyle: {
				// 			//   opacity: 0.5,
				// 			color: "#FE7C2F",
				// 		},
				// 	},
				// ];
				const series = getPie3D(optionsData, 0); // 可做为调整内环大小 0为实心圆饼图，大于0 小于1 为圆环
				series.push({
					name: "pie2d",
					type: "pie",
					label: {
						opacity: 1,
						fontSize: 14,
						lineHeight: 20,
						textStyle: {
							fontSize: 14,
							color: "#fff",
						},
					},
					labelLine: {
						length: 30,
						length2: 30,
					},
					startAngle: -20, //起始角度，支持范围[0, 360]。
					clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
					radius: ["40%", "60%"],
					center: ["50%", "50%"],
					data: optionsData,
					itemStyle: {
						opacity: 0,
					},
				});
				// 准备待返回的配置项，把准备好的 legendData、series 传入。
				var option = {
					legend: {
						show: false,
						tooltip: {
							show: true,
						},
						orient: "vertical",
						data: ["猪", "鸡", "牛", "鸭"],
						top: "center",
						itemGap: 14,
						itemHeight: 8,
						itemWidth: 17,
						right: "2%",
						textStyle: {
							color: "#fff",
							fontSize: 12,
						},
					},
					animation: true,
					tooltip: {
						formatter: (params) => {
							if (
								params.seriesName !== "mouseoutSeries" &&
								params.seriesName !== "pie2d"
							) {
								return `${
				                params.seriesName
				              }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
				                params.color
				              };"></span>${
				                option.series[params.seriesIndex].pieData.value + "次"
				              }`;
							}
						},
						textStyle: {
							fontSize: 14,
						},
					},
					title: {
						x: "center",
						top: "20",
						textStyle: {
							color: "#fff",
							fontSize: 22,
						},
					},
					// backgroundColor: "#0E3567",
					labelLine: {
						show: true,
						lineStyle: {
							color: "#7BC0CB",
						},
						normal: {
							show: true,
							length: 10,
							length2: 10,
						},
					},
					label: {
						show: true,
						position: "outside",
						formatter: "{b} \n{d}%",
						textStyle: {
							color: "#fff",
							fontSize: "18px",
						},
					},
					xAxis3D: {
						min: -1,
						max: 1,
					},
					yAxis3D: {
						min: -1,
						max: 1,
					},
					zAxis3D: {
						min: -1,
						max: 1,
					},
					grid3D: {
						show: false,
						boxHeight: 4,
						//top: '30%',
						bottom: "50%",
						// environment: "rgba(255,255,255,0)",
						viewControl: {
							distance: 270,
							alpha: 40,
							beta: 30,
							autoRotate: false, // 自动旋转
						},
					},
					series: series,
				};

				myChart.setOption(option);


			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>