import { render, staticRenderFns } from "./rightCenter.vue?vue&type=template&id=780896d2&scoped=true"
import script from "./rightCenter.vue?vue&type=script&lang=js"
export * from "./rightCenter.vue?vue&type=script&lang=js"
import style0 from "./rightCenter.vue?vue&type=style&index=0&id=780896d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780896d2",
  null
  
)

export default component.exports