<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="myEcharts3" style="width:100%;height:100%;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				let that = this
				let myChart = echarts.init(document.getElementById("myEcharts3"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});


				var scaleData = [{
						'name': '工程建设',
						'value': 10
					},
					{
						'name': '产权交易',
						'value': 10
					},
					{
						'name': '土地交易',
						'value': 10
					},
					{
						'name': '其他交易',
						'value': 10
					}

				];

				var sum = 0

				var rich = {
					white: {
						color: '#fff',
						align: 'center',
						fontWeight: 'bold',
						padding: [3, 0]
					}
				};
				var placeHolderStyle = {
					normal: {
						label: {
							show: false
						},
						labelLine: {
							show: false
						},
						color: 'rgba(0, 0, 0, 0)',
						borderColor: 'rgba(0, 0, 0, 0)',
						borderWidth: 0
					}
				};
				var data = [];
				var data1 = [];
				var color1 = ['#009cff', '#00fff0', '#f1bb00', '#00f439'];

				for (var i = 0; i < scaleData.length; i++) {
					sum += scaleData[i].value

					data.push({
						value: scaleData[i].value,
						name: scaleData[i].name,
						itemStyle: {
							normal: {
								borderWidth: 4,
								shadowBlur: 10,
								// borderColor:color1[i],
								shadowColor: color1[i],
								color: color1[i],
								opacity: 0.75,
							}
						}
					}, {
						value: scaleData[i].value / 50,
						name: '',
						itemStyle: placeHolderStyle
					});

					data1.push({
						value: scaleData[i].value,
						name: scaleData[i].name,
						itemStyle: {
							normal: {
								borderWidth: 4,
								// shadowBlur: 10,
								// borderColor:color1[i],
								// shadowColor: color1[i],
								color: color1[i],
								opacity: 0.3,
							}
						}
					}, {
						value: scaleData[i].value / 50,
						name: '',
						itemStyle: placeHolderStyle
					});
				}
				var seriesObj = [{
						name: '',
						type: 'pie',
						left:-70,
						clockWise: true,
						radius: [70, 80],
						hoverAnimation: false,
						itemStyle: {
							normal: {
								label: {
									show: false,
									position: 'inner',
									fontWeight: 'bold',
									formatter: function(params) {
										var percent = 0;
										var total = 0;
										for (var i = 0; i < scaleData.length; i++) {
											total += scaleData[i].value;
										}
										percent = ((params.value / total) * 100).toFixed(0);
										if (params.name !== '') {
											return params.name + '\n{white|' + '占比' + percent + '%}';
										} else {
											return '';
										}
									},
									rich: rich
								},
								labelLine: {
									show: false,
								}
							}
						},
						data: data
					},
					{
						name: '',
						type: 'pie',
						left:-70,
						clockWise: true,
						radius: [50, 70],
						hoverAnimation: false,
						itemStyle: {
							normal: {
								label: {
									show: false,
									position: 'inner',
									fontWeight: 'bold',
									formatter: function(params) {
										var percent = 0;
										var total = 0;
										for (var i = 0; i < scaleData.length; i++) {
											total += scaleData[i].value;
										}
										percent = ((params.value / total) * 100).toFixed(0);
										if (params.name !== '') {
											return params.name + '\n{white|' + '占比' + percent + '%}';
										} else {
											return '';
										}
									},
									rich: rich
								},
								labelLine: {
									show: false,
								}
							}
						},
						data: data1
					}
				];
				var option = {
					backgroundColor: '#04243E',
					tooltip: {
						show: false
					},
					legend: {
						show: true,
						x: 'right',
						y: 'center',
						icon: 'circle',
						itemGap: 10,
						textStyle: {
							color: '#fff',
							fontSize: 12
						}
					},
					grid: {
						left: '-3%',
						right: '4%',
						bottom: '3%',
						top: '8%',
						containLabel: true
					},
					toolbox: {
						show: false
					},
					series: seriesObj
				}


				myChart.setOption(option);
			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>