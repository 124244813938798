<template>
	<div>
		<div class="flexCol alit justc" style="position: relative;width:100%;height: 100%;">
			<div id="bottomRight" style="width:100%;height:100%;position: relative;z-index: 9;"></div>
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/bottom/jinbi2.png"
				class="bg"></img>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.$request('/center/home/middleBottomThree').then(res => {
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].name = res.data[i].type
					res.data[i].value = Math.abs(res.data[i].count)
				}
				this.init(res.data);
			})
		},
		methods: {
			init(data) {
				let that = this
				let myChart = echarts.init(document.getElementById("bottomRight"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});


				// let data = [{
				// 		value: 645,
				// 		name: '培训'
				// 	},
				// 	{
				// 		value: 890,
				// 		name: '考察'
				// 	},
				// 	{
				// 		value: 560,
				// 		name: '产业对接'
				// 	},
				// 	{
				// 		value: 587,
				// 		name: '展会'
				// 	}, {
				// 		value: 587,
				// 		name: '招商'
				// 	},
				// ];
				let color = ['#FF7377', '#F0CA59', '#48D5D5', '#409EFF', '#2A82E4'];
				var option = {
					//你的代码
					// backgroundColor: "#000",
					color: color,
					tooltip: {
						show: true,
						backgroundColor: 'rgba(13,5,30,.6)',
						borderWidth: 1,
						borderColor: '#32A1FF',
						padding: 5,
						textStyle: {
							color: '#fff'
						},
						confine: true,
						// extraCssText: "box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);",
						formatter(params) {
							if (params.name === '') {
								return '';
							}
							return `${params.name} : ${params.value}次 占比 : ${params.percent}%`;
						},
					},
					series: [{
						name: '',
						type: 'pie',
						radius: ['50%', '70%'],
						center: ['50%', '40%'], // 修改为居中
						avoidLabelOverlap: true,
						label: {
							color: '#fff',
							// alignTo: 'labelLine',
							formatter: '{name|{b}} {zb|{d}}%',
							minMargin: 5,
							edgeDistance: 10,
							lineHeight: 15,
							rich: {
								num: {
									fontSize: 12,
									color: '#FFFFFF'
								},
								zb: {
									fontSize: 12,
									color: '#FFFFFF'
								}
							}
						},
						labelLine: {
							length: 5,
							length2: 5,
						},
						data: data
					}]
				};


				myChart.setOption(option);
				setInterval(function() {
					myChart.clear()
					myChart.setOption(option);
				}, 30000)

			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/bottom/Group%204%402x.png'); */
		position: absolute;
		/* width: 8.3vw; */
		height: 15vh;
		background-size: 100% 100%;
		top: 2vh;
		/* left: 29%; */
		z-index: inherit;
	}
</style>