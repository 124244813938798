<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="rightBottom" style="width:100%;height:100%;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.$request('/center/home/rightThree').then(res => {
				let da = []
				for (var i = 0; i < res.data.length; i++) {
					da.push([res.data[i].date, res.data[i].count / 100000000])
				}
				this.init(res.data, da);
			})
		},
		methods: {
			init(pageData, da) {
				let that = this
				let myChart = echarts.init(document.getElementById("rightBottom"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});

				let xLabel = pageData.map(obj => obj.date);
				let dataValue = pageData.map(obj => obj.count / 100000000)

				let option = {
					// backgroundColor:'#0D2038',
					color: ['#1890FF'],
					tooltip: {
						borderWidth:0,
						trigger: 'axis',
						padding: 0,
						backgroundColor: 'none',
						axisPointer: {
							lineStyle: {
								color: 'rgb(126,199,255)',
							},
						},
						position: function(point, params, dom, rect, size) {
							// 固定在顶部
							return [point[0] - 84, point[1] - 85];

							// width: 160px;
							// height: 90px;
						},
						formatter(e) {
							return `<div class="tip flexCol alit justc">
				        					<div class="flex">
				        						<div class="yuan">${e[0].value}</div>
				        						<div class="yuan-type">亿元</div>
				        					</div>
				        					<div class="jye">${e[0].name}交易额</div>
				        				</div>`
						}

					},
					grid: {
						top: '3%',
						left: '3%',
						right: '4%',
						bottom: '8%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						axisLine: {
							//坐标轴轴线相关设置。数学上的x轴
							show: true,
							lineStyle: {
								color: 'rgb(41,188,245)',
							},
						},
						axisLabel: {
							//坐标轴刻度标签的相关设置
							formatter: function(value) {
								var str = "";
								var num = 3; //每行显示字数
								var valLength = value.length; //该项x轴字数
								var rowNum = Math.ceil(valLength / num); // 行数
					
								if (rowNum > 1) {
									for (var i = 0; i < rowNum; i++) {
										var temp = "";
										var start = i * num;
										var end = start + num;
					
										temp = value.substring(start, end) + "\n";
										str += temp;
									}
									return str;
								} else {
									return value;
								}
							},
							textStyle: {
								color: '#FFFFFF',
								fontSize: 12,
							},
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#233653',
							},
						},
						axisTick: {
							show: false,
						},
						data: xLabel,
					}, ],
					yAxis: [{
						name: "单位：亿",
						nameTextStyle: {
							color: "#fff",
							fontSize: 12,
							padding: [0, 0, 0, 0]
						},
						// minInterval: 1,
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								color: '#1160a0',
								type: 'dashed'
							},
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#008de7',
							},
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: 12
							}
						},
						axisTick: {
							show: false,
						},
					}, ],
					series: [{
							// name: '收入统计',
							type: 'line',
							smooth: false,
							symbol: 'circle', //拐点设置为实心
							symbolSize: 8, //拐点大小
							itemStyle: {
								normal: {
									borderColor: 'rgba(42,157,255,.2)', //拐点边框颜色
									borderWidth: 10, //拐点边框大小，
									color: '#1890FF'
								},
							},
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									// 坐标轴指示器，坐标轴触发有效
									type: 'line' // 默认为直线，可选为：'line' | 'shadow'
								}
							},
							lineStyle: {
								normal: {
									width: 3,
									shadowColor: "#1890FF",
									shadowBlur: 20
								}
							},
							areaStyle: {
								opacity: 1,
								//右下左上
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: 'rgba(24, 144, 255, .5)'
									},
									{
										offset: 0.3,
										color: 'rgba(24, 144, 255, 0.2)'
									},
									{
										offset: 1,
										color: 'rgba(24, 144, 255, 0)'
									}
								])
							},
							data: dataValue
						},
						{
							type: 'lines',
							coordinateSystem: 'cartesian2d',
							symbolSize: 4,
							polyline: true, // 多线段
							effect: {
								show: true,
								period: 5,
								trailLength: 0.3,
								symbolSize: 11,
								symbol: 'circle',
								color: '#0069d9',
							},
							lineStyle: {
								normal: {
									width: 1,
									opacity: 0
								},
								//   curveness: 0.5,
								//   join: 'round'
							},
							// 光点
							data: [{
								coords: da
							}]
						}
					]
				};
				myChart.setOption(option);
				setInterval(function() {
					myChart.clear()
					myChart.setOption(option);
				}, 30000)
				var app = {
					currentIndex: -1,
				};
				setInterval(function() {
					var dataLen = option.series[0].data.length;

					// 取消之前高亮的图形
					myChart.dispatchAction({
						type: 'downplay',
						seriesIndex: 0,
						dataIndex: app.currentIndex
					});
					app.currentIndex = (app.currentIndex + 1) % dataLen;
					//console.log(app.currentIndex);
					// 高亮当前图形
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: app.currentIndex,
					});
					// 显示 tooltip
					myChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: app.currentIndex
					});


				}, 2000);
				
				return;
				
				var options = {
					// backgroundColor: '#0c2d55',
					tooltip: {
						trigger: 'axis',
						padding: 0,
						backgroundColor: 'none',
						borderWidth:0,
						borderColor:'none',
						axisPointer: {
							lineStyle: {
								color: 'rgb(126,199,255)',
							},
						},
						position: function(point, params, dom, rect, size) {
							// 固定在顶部
							return [point[0] - 84, point[1] - 85];

							// width: 160px;
							// height: 90px;
						},
						formatter(e) {
							return `<div class="tip flexCol alit justc">
										<div class="flex">
											<div class="yuan">${e[0].value}</div>
											<div class="yuan-type">亿元</div>
										</div>
										<div class="jye">${e[0].name}交易额</div>
									</div>`
						}

					},
					color: ["#ff7022", "#fff58a", "#46f8ff"],
					// legend: [{
					// 		data: [{
					// 			name: "1",
					// 			icon: "rect",
					// 		}, ],
					// 		itemWidth: 30,
					// 		itemHeight: 4,
					// 		top: "6%",
					// 		left: "35%",
					// 		textStyle: {
					// 			color: "#fff",
					// 			// fontWeight: "normal",
					// 			fontSize: 20,
					// 		},
					// 	},
					// 	{
					// 		data: [{
					// 			name: "2",
					// 			icon: "rect",
					// 		}, ],
					// 		itemWidth: 30,
					// 		itemHeight: 4,
					// 		top: "6%",
					// 		left: "50%",
					// 		textStyle: {
					// 			color: "#fff",
					// 			// fontWeight: "normal",
					// 			fontSize: 20,
					// 		},
					// 	},
					// 	{
					// 		top: "5.5%",
					// 		left: "65%",
					// 		textStyle: {
					// 			color: "#fff",
					// 			fontSize: 20,
					// 			fontFamily: "微软雅黑",
					// 		},
					// 		itemWidth: 20,
					// 		itemHeight: 20,
					// 		data: [{
					// 			name: "3",
					// 			icon: "rect",
					// 		}, ],
					// 	},
					// ],
					grid: {
						top: '15%',
						left: '14%',
						right: '5%',
						bottom: '15%',
					},
					xAxis: [{
						type: 'category',
						axisLine: {
							//坐标轴轴线相关设置。数学上的x轴
							show: true,
							lineStyle: {
								color: 'rgb(41,188,245)',
							},
						},
						axisLabel: {
							//坐标轴刻度标签的相关设置
							formatter: function(value) {
								var str = "";
								var num = 3; //每行显示字数
								var valLength = value.length; //该项x轴字数
								var rowNum = Math.ceil(valLength / num); // 行数

								if (rowNum > 1) {
									for (var i = 0; i < rowNum; i++) {
										var temp = "";
										var start = i * num;
										var end = start + num;

										temp = value.substring(start, end) + "\n";
										str += temp;
									}
									return str;
								} else {
									return value;
								}
							},
							textStyle: {
								color: '#FFFFFF',
								fontSize: 12,
							},
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#233653',
							},
						},
						axisTick: {
							show: false,
						},
						data: xLabel,
					}, ],
					yAxis: [{
						name: "单位：亿",
						nameTextStyle: {
							color: "#fff",
							fontSize: 12,
							padding: [0, 0, 0, 0]
						},
						// minInterval: 1,
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								color: '#1160a0',
								type: 'dashed'
							},
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#008de7',
							},
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: 12
							}
						},
						axisTick: {
							show: false,
						},
					}, ],
					series: [{
							name: '1',
							type: 'line',
							symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
							symbolSize: 8,
							smooth: true,
							itemStyle: {
								borderColor: '#ffffff', // 边框颜色
								borderWidth: 2, // 边框宽度
								borderType: 'solid', // 边框类型，这里是实线
								color: '#9c98ff'
							},
							lineStyle: {
								normal: {
									width: 3,
									color: 'rgba(138, 212, 255, 1)', // 线条颜色
								},
							},
							areaStyle: {
								normal: {
									//线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
									color: new echarts.graphic.LinearGradient(
										0,
										0,
										0,
										1,
										[{
												offset: 0,
												color: '#165DFF',
											},

											{
												offset: 1,
												color: 'rgba(22, 93, 255, 0.16)',
											},
										],
										false
									),
								},
							},
							data: dataValue,
						},
						// {
						// 	"name": "flow_因订立、履行、变更、解除和终止劳动合同发生的争议",
						// 	"type": "lines",
						// 	"polyline": true,
						// 	"showSymbol": false,
						// 	"coordinateSystem": "cartesian2d",
						// 	"effect": {
						// 		"trailLength": 0.3,
						// 		"show": true,
						// 		"period": 6,
						// 		"symbolSize": 4,
						// 		"loop": true
						// 	},
						// 	"lineStyle": {
						// 		"color": "#fff",
						// 		"width": 0,
						// 		"opacity": 0,
						// 		"curveness": 0,
						// 		"type": "dashed"
						// 	},
						// 	"data": [{
						// 		"coords": da
						// 	}]
						// }

					]
				}



			}
		}
	}
</script>

<style>
	.jye {
		font-weight: bold;
		font-size: 12px;
		color: #8AD4FF;
		text-align: center;
	}

	.yuan-type {
		font-weight: 500;
		font-size: 12px;
		color: #FF8600;
		margin-top: 4px;
	}

	.yuan {
		font-family: DINPro, DINPro;
		font-weight: 500;
		font-size: 18px;
		color: #FF8600;
		text-align: center;
	}

	.tip {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E8%B7%AF%E5%BE%84%402x.png');
		background-size: 100% 100%;
		width: 160px;
		height: 90px;
		border: none;
	}
</style>
<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>