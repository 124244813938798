<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="rightTop" style="width:100%;height:100%;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: [],
				myChart: ''
			}
		},
		mounted() {
			this.$request('/center/home/rightOne').then(res => {
				this.init(res.data.list);
			})
		},
		methods: {
			init(pageData) {
				let that = this
				this.myChart = echarts.init(document.getElementById("rightTop"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					that.myChart.resize();
				});
				console.log(that.myChart);
				var option = {
					grid: {
						left: '5%',
						right: '5%',
						bottom: '-7%',
						top: '2%',
						containLabel: true
					},
					tooltip: {
						show: false,
						trigger: 'axis',
						axisPointer: {
							type: 'none'
						},
						formatter: function(params) {
							return params[0].name + '<br/>' +
								"<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
								params[0].seriesName + ' : ' + Number((params[0].value.toFixed(4) / 10000).toFixed(
									2)).toLocaleString() + ' 万元<br/>'
						}
					},
					// backgroundColor: 'rgb(20,28,52)',
					xAxis: {
						show: false,
						type: 'value'
					},
					yAxis: [{
						type: 'category',
						inverse: true,
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff'
							},
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false
						},
						data: pageData.map(obj => obj.shopName)
					}, {
						type: 'category',
						inverse: true,
						axisTick: 'none',
						axisLine: 'none',
						show: true,
						axisLabel: {
							textStyle: {
								color: '#ffffff',
								fontSize: '12'
							},
							formatter: function(value) {
								if (value >= 10000) {
									return (value / 10000).toLocaleString() + '万';
								} else {
									return value.toLocaleString() + '%';
								}
							},
						},
						data: pageData.map(obj => obj.finishRate * 100)
					}],
					series: [{
							name: '检测率',
							type: 'bar',
							zlevel: 1,
							itemStyle: {
								normal: {
									barBorderRadius: 30,
									color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0,
										color: 'rgb(57,89,255,1)'
									}, {
										offset: 1,
										color: 'rgb(46,200,207,1)'
									}]),
								},
							},
							label: {
								show: false
							},
							barWidth: 10,
							data: pageData.map(obj => obj.finishRate * 100)
						},
						{
							name: '背景',
							type: 'bar',
							barWidth: 10,
							barGap: '-100%',
							data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
							itemStyle: {
								normal: {
									color: 'rgba(24,31,68,1)',
									barBorderRadius: 30,
								}
							},
						},
					]
				};

				this.myChart.setOption(option);

				setInterval(function() {
					that.myChart.clear()
					that.myChart.setOption(option);
				}, 15000)
			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}

	.bg {
		width: 30rpx;
		height: 30rpx;

	}
</style>